import delve from 'dlv'
import moment from 'moment/moment'

export function formatDestinations(data, withParent = true) {
  const destinations = {}

  if (!data) return null

  data.forEach((item) => {
    if (!item.parent) {
      destinations[item.id] = { ...item, children: withParent ? [item] : [] }
    }
  })

  data.forEach((item) => {
    if (item.parent) {
      const result = data.find(d => d['@id'] === item.parent)

      if (destinations[result.id]) {
        destinations[result.id].children.push(item)
      }
    }
  })

  return Object.values(destinations).filter(item => !item.parent)
}

export function formatCompaniesForShipsPage(data) {
  const cruiselines = {}

  if (!data) return null

  data.forEach((item) => {
    if (!cruiselines[item.cruiseline.code]) {
      cruiselines[item.cruiseline.code] = {
        name: item.cruiseline.name,
        ships: [],
      }
    }

    if (item.activated) {
      item.path = { name: 'ships-slug', params: { slug: item.slug } }
      cruiselines[item.cruiseline.code].ships.push(item)
    }
  })

  return Object.values(cruiselines)
}

export function formatStrapiDestination(data) {
  if (!data) return null

  if (data.employee && data.employee.data) {
    let pictureData = data.employee.data.attributes.picture.data.attributes
    delete data.employee.data.attributes.picture

    data.employee = {
      id: data.employee.data.id,
      picture: pictureData,
      ...data.employee.data.attributes,
    }
  }
  else {
    delete data.employee
  }

  if (data.advantage && data.advantage.tabs) {
    for (let tab of data.advantage.tabs) {
      tab.component = 'destination_advantage'

      tab.props = {
        id: tab.id,
        title: tab.title,
        description: tab.description,
      }

      delete tab.description
      delete tab.id

      if (tab.slider.data) {
        let sliderArray = []

        for (let slider of tab.slider.data) {
          sliderArray.push(slider.attributes.url)
        }

        delete tab.slider

        tab.props.slider = sliderArray

        delete tab.description
        delete tab.id
      }
      else {
        delete tab.slider
      }
    }
  }

  return data
}

export function formatAboutEmployee(employees) {
  if (!employees) return null

  for (let employee of employees) {
    if (employee.attributes) {
      if (employee.attributes.picture.data && employee.attributes.picture.data.attributes) {
        let pictureUrl = employee.attributes.picture.data.attributes.url
        delete employee.attributes.picture

        employee.attributes.picture = pictureUrl
      }
    }
  }

  return employees
}

export function formatAboutHeaderContent(content) {
  let pictureArray = []

  if (!content) return null

  if (content.picture.data) {
    for (let picture of content.picture.data) {
      if (picture.attributes) {
        pictureArray.push(picture.attributes.url)
      }
    }
  }

  delete content.picture.data

  content.picture = pictureArray

  return content
}

export function formatSpecialization(specializations) {
  let specializationsArray = []

  if (!specializations) return null

  for (let specialization of specializations) {
    specializationsArray.push(specialization.attributes)
  }

  return specializationsArray
}

export function formatService(services) {
  let servicesArray = []

  if (!servicesArray) return null

  for (let service of services) {
    servicesArray.push(service.attributes)
  }

  return servicesArray
}

export function formatArticles(articles) {
  let articlesArray = []

  if (!articles) return null

  for (let article of articles) {
    let pictureUrl = delve(article.attributes.picture, 'data.attributes.url', '')
    delete article.attributes.picture

    article = {
      id: article.id,
      ...article.attributes,
      picture: pictureUrl,
    }

    article.picture = pictureUrl

    articlesArray.push(article)
  }

  return articlesArray
}

export function formatItineraries(data) {
  let uniqueId = 1
  let itinerariesArray = []
  let currentItinerary = null

  if (!data) return null

  data.forEach((item) => {
    let currentWeek = Math.ceil(item.day / 7)

    if (item.port.name === 'Cruising' || item.port.name === 'At sea' || item.port.name === 'En mer') {
      if (!currentItinerary) {
        currentItinerary = {
          name: 'En Mer',
          visual: delve(item.port.place, 'image.url', ''),
          countryName: '',
          description: delve(item.port.place, 'description', ''),
          startDay: item.day,
          endDay: item.day,
        }
      }
      else {
        currentItinerary.endDay = item.day
      }
    }
    else {
      if (currentItinerary) {
        let dayText = currentItinerary.startDay === currentItinerary.endDay
          ? `${currentItinerary.startDay}`
          : `${currentItinerary.startDay} à ${currentItinerary.endDay}`

        itinerariesArray.push({
          uniqueId: uniqueId++,
          weekNumber: currentWeek,
          day: dayText,
          ...currentItinerary,
        })
        currentItinerary = null
      }

      itinerariesArray.push({
        uniqueId: uniqueId++,
        name: delve(item.port, 'name', ''),
        countryName: delve(item.port.place, 'countryName', ''),
        description: delve(item.port.place, 'description', ''),
        visual: delve(item.port.place, 'image.url', ''),
        day: item.day,
        weekNumber: currentWeek,
        arrivalTime: item.arrivalTime,
        departureTime: item.departureTime,
        ...item,
      })
    }
  })

  return itinerariesArray
}

export function formatFilters(entries) {
  const formattedFilters = []

  let i = 0
  for (const [key, value] of Object.entries(entries)) {
    formattedFilters.push({
      uniqueId: value + '-' + i,
      label: value,
    })
    i++
  }

  return formattedFilters
}

export function formatCabinVisual(cabins) {
  let cabinVisuals = []

  if (!cabins) return null

  if (Array.isArray(cabins)) {
    cabins.forEach((cabin) => {
      cabinVisuals.push(delve(cabin, 'image.url', ''))
    })
  }
  else {
    for (const key in cabins) {
      if (Object.prototype.hasOwnProperty.call(cabins, key)) {
        const cabin = cabins[key]

        cabinVisuals.push(delve(cabin, 'image.url', ''))
      }
    }
  }

  return cabinVisuals
}

export function formatCabin(data) {
  let cabins = []

  if (!data) return null

  if (Array.isArray(data)) {
    data.forEach((cabin) => {
      cabins.push(
        ...cabin,
      )
    })
  }
  else {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const cabin = data[key]

        cabins.push(
          cabin,
        )
      }
    }
  }

  return cabins
}

export function formatShipServicesForTab(services) {
  let servicesArray = []

  if (!services) return servicesArray

  services.forEach((service) => {
    servicesArray.push({
      title: service.label,
      icon: service.icon ? service.icon : '',
      component: 'ship_content',
      props: {
        title: service.label,
        description: service.description,
        details: delve(service, 'details', ''),
        visual: delve(service, 'image.url', ''),
      },
    })
  })

  return servicesArray
}

export function formatDatePrice(data) {
  let transformed = []

  if (!data) return null

  data.forEach((item, index) => {
    if (!item.departureDate) return

    const departureDate = new Date(item.departureDate)
    const year = departureDate.getFullYear()
    const day = departureDate.getDate()
    const label = `${day} ${departureDate.toLocaleString('fr-FR', { month: 'long' })} ${year}`
    const price = item.bestPrice

    transformed.push({
      uniqueId: `departure-${Object.keys(index).length + 1}`,
      label: `${departureDate.toLocaleString('fr-FR', { month: 'long' })} ${year}`,
      departures: [],
    })

    transformed[index].departures.push({
      uniqueId: `departure-item-${index + 1}`,
      label: label,
      price: price,
    })
  })

  return true
}

export function formatQueryToTenderBody(query, durationsMaxMapping) {
  const transformedObject = {}
  const authorizedKeys = [
    'durationFrom',
    'durationTo',
    'sailings.hits.departureDateFrom',
    'sailings.hits.departureDateTo',
    'ship.cruiseline.name',
    'departurePort.place.name',
    'ship.name',
    'sailings.hits.prices.hits.priceFrom',
    'allDestinations.name',
  ]
  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key) && authorizedKeys.includes(key)) {
      if (key === 'durationFrom') {
        let value = query[key]
        let durationFrom = Math.min(value)
        let durationTo = Math.max(value)
        transformedObject['durationFrom'] = durationFrom
        transformedObject['durationTo'] = durationsMaxMapping[durationTo]
      }
      else if (key === 'sailings.hits.departureDateFrom') {
        transformedObject['sailings.hits.departureDateFrom'] = query[key][0]
        transformedObject['sailings.hits.departureDateTo'] = query[key][1]
      }
      else if (key === 'sailings.hits.prices.hits.priceFrom') {
        transformedObject[key] = query[key][0]
        transformedObject['sailings.hits.prices.hits.priceTo'] = query[key][1]
      }
      else {
        if (typeof (query[key]) !== 'object') {
          query[key] = [query[key]]
        }
        transformedObject[key] = query[key].map(item => item.trim())
      }
    }
  }

  return transformedObject
}

export function transformOriginalQuery(originalObj) {
  let updatedObj = {}

  for (let key in originalObj) {
    if (Object.prototype.hasOwnProperty.call(originalObj, key)) {
      let value = originalObj[key]
      if (typeof value === 'string' && value.includes('|')) {
        let arrayValue = value.split('|')
        updatedObj[key] = arrayValue
      }
      else {
        updatedObj[key] = value
      }
    }
  }

  return updatedObj
}

export function generateMonths() {
  const result = []
  const currentDate = moment()
  const startDate = currentDate.add(1, 'month')

  for (let i = 0; i < 36; i++) {
    const monthMoment = moment(startDate).add(i, 'month')
    const label = monthMoment.format('MMMM YYYY')
    const uniqueId = monthMoment.format('YYYY-MM-DD')

    result.push({ label, uniqueId })
  }

  return result
}

export function formatQuoteCruise(data, formattedSailings) {
  if (!data) return null
  const departuresDate = []
  let departureDateCabin = []

  formattedSailings.forEach((item) => {
    let obj = {
      departureDate: item.departureDate,
      packageId: delve(item, 'packageId', ''),
    }

    if (item.prices) {
      item.prices.hits.forEach((itemPrice) => {
        Object.entries(item.cabinCategoryCodeToDisplay).forEach(([code]) => {
          const matchingPrices = itemPrice.code === code

          if (!departureDateCabin.some(cabin => cabin.type === 'yacht_club') && matchingPrices) {
            departureDateCabin.push({
              type: 'yacht_club',
              price: itemPrice.price,
            })
          }
        })

        if (!departureDateCabin.some(cabin => cabin.type === itemPrice.type)) {
          departureDateCabin.push({
            type: itemPrice.type,
            price: item.bestCabinTypePrice[itemPrice.type],
          })
        }
      })

      obj.cabins = departureDateCabin
      departureDateCabin = []
    }

    departuresDate.push(obj)
  })

  return {
    id: delve(data, 'id', ''),
    tenderId: delve(data, '@id', ''),
    code: delve(data, 'code', ''),
    name: delve(data, 'content.name', ''),
    duration: delve(data, 'duration', ''),
    departurePort: delve(data, 'departurePort.name', ''),
    boatName: delve(data, 'ship.name', ''),
    price: delve(data, 'bestPrice', ''),
    visual: delve(data, 'image.url', ''),
    cabins: formatCabin(delve(data, 'ship.cabins', '')),
    departuresDate: departuresDate,
  }
}

export function formatMaxMinPrice(query) {
  for (let key in query) {
    if (key === 'sailings.hits.prices.hits.priceFrom') {
      if (query[key] === '200') {
        query[key] = '1'
      }
    }
    else if (key === 'sailings.hits.prices.hits.priceTo') {
      if (query[key] === '10000') {
        query[key] = '1000000'
      }
    }
  }
  return query
}

export function addBestPriceCabin(hits) {
  let minPrice = Math.min(...hits.map(obj => obj.bestPrice))

  hits.forEach((hit) => {
    hit.isBestPrice = hit.bestPrice === minPrice
    if (typeof hit.prices?.hits !== 'undefined') {
      hit.bestCabinTypePrice = getLowestCabinTypePrices(hit.prices?.hits)
    }
  })

  return hits
}

export function addYachtClubPrice(hits) {
  hits.forEach((hit) => {
    hit.hasYachtClubPrice = typeof hit.cabinCategoryCodeToDisplay === 'object' && hit.cabinCategoryCodeToDisplay !== null && Object.keys(hit.cabinCategoryCodeToDisplay).length > 0

    if (!hit.cabinCategoryCodeToDisplay) return

    Object.entries(hit.cabinCategoryCodeToDisplay).forEach(([code]) => {
      const matchingPrices = hit.prices.hits.filter(priceHit => priceHit.code === code)

      if (matchingPrices.length > 0) {
        const bestPrice = Math.min(...matchingPrices.map(priceHit => priceHit.price))

        if (!hit.bestCabinTypePrice.yacht_club || hit.bestCabinTypePrice.yacht_club > bestPrice) {
          hit.bestCabinTypePrice.yacht_club = bestPrice
        }
      }
    })
  })

  return hits
}

export function formatSailingHitsForProductPage(sailings) {
  let formattedSailings
  formattedSailings = addBestPriceCabin(sailings)
  formattedSailings = addYachtClubPrice(formattedSailings)

  return formattedSailings
}

export function formatSailingHits(hits) {
  hits.forEach((hit) => {
    hit.isBestPrice = hit.bestPrice === Math.min(...hits.map(obj => obj.bestPrice))
    if (typeof hit.prices?.hits !== 'undefined') {
      hit.bestCabinTypePrice = getLowestCabinTypePrices(hit.prices?.hits)
    }
  })

  hits.sort((a, b) => b.departureDate - a.departureDate)

  return hits
}

function getLowestCabinTypePrices(cabins) {
  const lowestPrices = {}

  cabins.forEach((cabin) => {
    if ((!lowestPrices[cabin.type] || cabin.price < lowestPrices[cabin.type]) && cabin.price > 0) {
      lowestPrices[cabin.type] = cabin.price
    }
  })

  return lowestPrices
}

export function formatDeparturePort(departurePorts) {
  let formattedDeparturePort = {}

  departurePorts.forEach((departurePort) => {
    if (!formattedDeparturePort[departurePort.category]) {
      formattedDeparturePort[departurePort.category] = []
    }

    formattedDeparturePort[departurePort.category].push(departurePort)
  })

  return formattedDeparturePort
}

export function sortArrayWithMapping(array, nameKey, mapping) {
  array.sort((a, b) => {
    const indexA = mapping.indexOf(a[nameKey])
    const indexB = mapping.indexOf(b[nameKey])

    return (indexA === -1 ? mapping.length : indexA) - (indexB === -1 ? mapping.length : indexB)
  })

  return array
}

export function extractCarouselImages(data) {
  let images = []

  if (data.image) {
    images.push(data.image)
  }

  if (data.mapImage) {
    images.push(data.mapImage)
  }

  const seenStopover = new Set()

  const stopOvers = data.stopovers.hits.filter((item) => {
    if (!seenStopover.has(item.port.name) && item.port.place && item.port.place.image && item.port.place.image.url !== data.image.url) {
      seenStopover.add(item.port.name)
      return true
    }
    return false
  })

  if (stopOvers) {
    const stopoversImages = stopOvers.slice(0, 2)
      .map(stopover => stopover?.port?.place?.image)
      .filter(Boolean)
    images.push(...stopoversImages)
  }

  if (data.ship && data.ship.image) {
    images.push(data.ship.image)
  }

  if (data.ship && data.ship.images) {
    const shipImages = data.ship.images.slice(0, 1)
      .map(shipImage => shipImage)
      .filter(Boolean)
    images.push(...shipImages)
  }

  images = images.filter(image => Boolean(image.url))

  const seenUrls = new Set()
  const seenFilenames = new Set()

  images = images.filter((image) => {
    const url = image.url
    const filename = url.substring(url.lastIndexOf('/') + 1)

    if (seenUrls.has(url) || seenFilenames.has(filename)) {
      return false
    }

    seenUrls.add(url)
    seenFilenames.add(filename)

    return true
  })

  return images
}

function placeClosestDateFirst(data, targetDate) {
  const target = new Date(targetDate)

  let closestIndex = 0
  let closestDiff = Math.abs(new Date(data[0].departureDate) - target)

  for (let i = 1; i < data.length; i++) {
    const currentDiff = Math.abs(new Date(data[i].departureDate) - target)
    if (currentDiff < closestDiff) {
      closestDiff = currentDiff
      closestIndex = i
    }
  }

  const [closestObject] = data.splice(closestIndex, 1)
  data.unshift(closestObject)

  return data
}

export function formatPrice(price) {
  if (!price) return
  return price.toLocaleString('fr-FR').replace(/\u202F/g, ' ')
}

export function formatEmployee(data) {
  const employeesArray = []

  if (!data) return null

  data.forEach((employee) => {
    employeesArray.push({
      id: employee.id,
      name: employee.attributes.name,
      picture: employee.attributes.picture,
    })
  })

  return employeesArray
}

export function getNumberFromKey(obj, value) {
  for (const key in obj) {
    if (obj[key] === value) {
      const match = key.match(/\((\d+)\)/)
      return match ? parseInt(match[1], 10) : null
    }
  }
  return 0
}

export function formatDateDefault(date= null) {
  if ('undefined' === typeof date || '' === date || null === date) {
    return moment().format('YYYY-MM-DD')
  }

  return moment(date).format('YYYY-MM-DD')
}
